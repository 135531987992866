// Table.tsx
import React from "react"
import {
  GridRowModel,
  GridColDef,
  GridRowsProp,
  GridColumnVisibilityModel,
  GridSortModel,
  GridCallbackDetails,
} from "@mui/x-data-grid"
import StyledDataGrid from "./styles"
import CustomNoRowsOverlay from "./CustomNoRowsOverlay"

interface TableProps {
  rows: GridRowsProp
  columns: GridColDef[]
  processRowUpdate?: (newRow: GridRowModel) => Promise<GridRowModel>
  loading: boolean
  rowCount: number
  pageSizeOptions?: number[]
  paginationModel?: any
  paginationMode?: any
  onPaginationModelChange?: (model: any) => void
  disableColumnFilter?: boolean
  onCellClick?: (params: any) => void
  columnVisibilityModel?: GridColumnVisibilityModel | undefined
  sortModel?: GridSortModel | undefined
  onSortModelChange?:
    | ((model: GridSortModel, details: GridCallbackDetails) => void)
    | undefined
  height?: string
}

const Table: React.FC<TableProps> = ({
  rows,
  columns,
  processRowUpdate,
  loading,
  rowCount,
  pageSizeOptions,
  paginationModel,
  onPaginationModelChange,
  disableColumnFilter,
  onCellClick,
  paginationMode,
  columnVisibilityModel,
  sortModel,
  onSortModelChange,
  height = "550px",
}) => {
  return (
    <div style={{ height: height, width: "100%" }}>
      <StyledDataGrid
        initialState={{
          columns: {
            columnVisibilityModel,
          },
        }}
        rows={rows}
        columns={columns}
        processRowUpdate={processRowUpdate}
        loading={loading}
        rowCount={rowCount}
        pageSizeOptions={pageSizeOptions}
        paginationModel={paginationModel}
        paginationMode={paginationMode}
        onPaginationModelChange={onPaginationModelChange}
        disableColumnFilter={disableColumnFilter}
        onCellClick={onCellClick}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        sortModel={sortModel}
        onSortModelChange={onSortModelChange}
      />
    </div>
  )
}

export default Table
