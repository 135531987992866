import React from "react"
import {
  SelectChangeEvent,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Stack,
  Divider,
  InputAdornment,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectTransactions, setFilters } from "../../slices/transactionsSlice"
import { TransactionType, MarkedStatus, operators } from "../../constants/data"
import { Dayjs } from "dayjs"
import { RefreshRounded, Search } from "@mui/icons-material"
import {
  ResetButton,
  StyledFormControl,
  StyledFormControlSearch,
} from "./styles"
import DateRangeSelector from "../../components/DateRangeSelector/DateRangeSelector"
interface TransactionsFiltersProps {
  isSmallScreen: boolean
  resetFilterHandler: () => void
}

const TransactionsFilters: React.FC<TransactionsFiltersProps> = ({
  resetFilterHandler,
  isSmallScreen,
}) => {
  const {
    filters,
    isBankListLoading,
    bankList,
    paymentMethods,
    isPaymentMethodsLoading,
  } = useAppSelector(selectTransactions)
  const dispatch = useAppDispatch()

  const handleDateRangeSelection = (newState: {
    dateSelector: string
    startDate?: Dayjs | null
    endDate?: Dayjs | null
  }) => {
    dispatch(
      setFilters({
        ...filters,
        ...newState,
      }),
    )
  }

  const options = [{ accountName: "All", id: "all" }, ...bankList]

  return (
    <Stack
      direction={{
        md: "row",
        xs: "column",
      }}
      spacing={1}
      gap={{
        md: 1,
        xs: 0,
      }}
    >
      <StyledFormControlSearch size="small">
        <TextField
          size="small"
          variant="outlined"
          label="Search"
          placeholder="Search by Description & Reference No."
          value={filters.search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(setFilters({ ...filters, search: e.target.value }))
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormControlSearch>
      {!isSmallScreen && <Divider orientation="vertical" flexItem />}
      <StyledFormControl size="small">
        <InputLabel id="select-opertator">Operator</InputLabel>
        <Select
          labelId="select-opertator"
          id="selectOperator"
          label="Operator"
          value={filters.operator}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                operator: e.target.value as string,
              }),
            )
          }
          disabled={filters.search === ""}
        >
          {operators.map((operator) => (
            <MenuItem value={operator?.value}>{operator?.option}</MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <DateRangeSelector
        dateSelector={filters.dateSelector}
        filterState={filters}
        updateFilterState={handleDateRangeSelection}
      />
      <StyledFormControl size="small">
        <InputLabel id="select-transaction-type">Type</InputLabel>
        <Select
          labelId="select-transaction-type"
          id="selectTransactionType"
          label="Type"
          value={filters.transactionType}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                transactionType: e.target.value as string,
              }),
            )
          }
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value={TransactionType.DEBIT}>Debit</MenuItem>
          <MenuItem value={TransactionType.CREDIT}>Credit</MenuItem>
        </Select>
      </StyledFormControl>
      <StyledFormControl size="small">
        <InputLabel id="select-accounts">Account</InputLabel>
        <Select
          labelId="select-accounts"
          id="selectAccounts"
          label="Account"
          value={filters.accountId}
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                accountId: e.target.value as string,
              }),
            )
          }
          disabled={isBankListLoading}
        >
          {isBankListLoading ? (
            <MenuItem value="">Loading...</MenuItem>
          ) : (
            options.map((bank) => (
              <MenuItem key={bank.id} value={bank.id}>
                {bank.accountName}
              </MenuItem>
            ))
          )}
        </Select>
      </StyledFormControl>
      <StyledFormControl
        size="small"
        sx={{
          minWidth: "120px",
        }}
      >
        <InputLabel id="payment-method-select">Payment Method</InputLabel>
        <Select
          labelId="payment-method-select"
          id="paymentMethodSelect"
          value={filters.paymentMethod}
          label="Payment Method"
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                paymentMethod: e.target.value as string,
              }),
            )
          }
          disabled={isPaymentMethodsLoading}
        >
          <MenuItem value="all">All</MenuItem>
          {paymentMethods.map((method) => (
            <MenuItem value={method.key}>{method.value}</MenuItem>
          ))}
        </Select>
      </StyledFormControl>
      <StyledFormControl size="small">
        <InputLabel id="marked-status-select">Status</InputLabel>
        <Select
          labelId="marked-status-select"
          id="isMarked"
          value={filters.isMarked}
          label="Status"
          onChange={(e: SelectChangeEvent) =>
            dispatch(
              setFilters({
                ...filters,
                isMarked: e.target.value as string,
              }),
            )
          }
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value={MarkedStatus.MARKED}>Marked</MenuItem>
          <MenuItem value={MarkedStatus.UNMARKED}>Unmarked</MenuItem>
        </Select>
      </StyledFormControl>
      <ResetButton
        variant="outlined"
        startIcon={<RefreshRounded fontSize="small" />}
        color="inherit"
        onClick={resetFilterHandler}
      >
        Reset
      </ResetButton>
    </Stack>
  )
}

export default TransactionsFilters
