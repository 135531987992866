export class Transaction {
  id = ""
  date = ""
  description = ""
  amount = 0
  type = ""
  referenceNo = ""
  valueDate = ""
  branch = ""
  balance = 0
  isMarked = false
  invoiceNumber = ""
  invoiceId = ""
  invoiceURL = ""
  paymentId = ""
  paymentURL = ""
  payee = ""
  accountName = ""
  isReconciled = false
  isSynced = false
  invoiceSource = ""
  settlements = { payments: [], invoices: [] }
  constructor(transaction?: any) {
    if (transaction) {
      this.id = transaction?.id
      this.date = transaction?.date
      this.description = transaction?.description
      this.amount = transaction?.amount
      this.type = transaction?.type
      this.referenceNo = transaction?.reference_no
      this.valueDate = transaction?.value_date
      this.branch = transaction?.branch
      this.balance = transaction?.balance
      this.isMarked = transaction?.is_marked
      this.isReconciled = transaction?.is_reconciled
      this.isSynced = transaction?.is_synced
      this.invoiceNumber = transaction?.invoice_number
      this.invoiceId = transaction?.invoice_id
      this.invoiceURL = transaction?.invoice_url
      this.paymentId = transaction?.payment_id
      this.paymentURL = transaction?.payment_url
      this.payee = transaction?.payee
      this.accountName = transaction?.account_name
      this.settlements = transaction?.settlements
      this.invoiceSource = transaction?.invoice_source
    }
  }
}

export class Bank {
  id = ""
  accountName = ""

  constructor(bank?: any) {
    if (bank) {
      this.id = bank?.id
      this.accountName = bank?.account_name
    }
  }
}

export class Invoice {
  zohoInvoiceId = ""
  balance = Infinity
  companyName = ""
  customerId = ""
  customerName = ""
  date = ""
  dueDate = ""
  email = ""
  invoiceId = ""
  invoiceNumber = ""
  status = ""
  total = 0
  source = ""
  tds2Percent = ""
  tds10Percent = ""
  constructor(invoiceDetails?: any) {
    if (invoiceDetails) {
      this.invoiceId = invoiceDetails?.id
      this.balance = invoiceDetails?.balance
      this.customerName = invoiceDetails?.customer_name
      this.companyName = invoiceDetails?.company_name
      this.customerId = invoiceDetails?.customer_id
      this.date = invoiceDetails?.date
      this.dueDate = invoiceDetails?.due_date
      this.email = invoiceDetails?.email
      this.zohoInvoiceId = invoiceDetails?.invoice_id
      this.invoiceNumber = invoiceDetails?.number
      this.status = invoiceDetails?.status
      this.total = invoiceDetails?.total
      this.source = invoiceDetails?.source
      this.tds2Percent = invoiceDetails?.tds_2_percent
      this.tds10Percent = invoiceDetails?.tds_10_percent
    }
  }
}

export class InvoiceEnqueue {
  invoice_id = ""
  invoice_number = ""
  is_tds_deducted = ""
  amount = ""
  tax_amount_withheld = ""
  constructor(InvoiceEnqueue?: any) {
    if (InvoiceEnqueue) {
      this.invoice_id = InvoiceEnqueue?.invoiceId
      this.invoice_number = InvoiceEnqueue?.invoiceNumber
      this.is_tds_deducted = InvoiceEnqueue?.tdsDeducted
      this.tax_amount_withheld = InvoiceEnqueue?.tdsDeducted
        ? InvoiceEnqueue?.tdsAmountWithHeld
        : 0
      this.amount = InvoiceEnqueue?.amount
    }
  }
}

export class ProcessSchedule {
  message = ""
  status = false
  nextRun = ""
  scheduleStatus = ""
  id = ""
  name = ""
  constructor(ProcessSchedule?: any) {
    if (ProcessSchedule) {
      this.message = ProcessSchedule?.message
      this.status = ProcessSchedule?.data?.status
      this.nextRun = ProcessSchedule?.data?.details?.next_run
      this.scheduleStatus = ProcessSchedule?.data?.details?.status
      this.id = ProcessSchedule?.data?.details?.id
      this.name = ProcessSchedule?.data?.details?.name
    }
  }
}
