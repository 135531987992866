import {
  Box,
  Chip,
  Drawer,
  FormControl,
  IconButton,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material"
import theme from "../../app/theme"
import { StyledButton } from "./StyledButton"

export const StyledFormControl = styled(FormControl)`
  flex: 1;
`

export const StyledFormControlSearch = styled(FormControl)`
  flex: 1.5;
`

export const TransactionsContainer = styled(Stack)`
  background: white;
  padding: 20px;
  gap: 12px;
`

export const ResetButton = styled(StyledButton)`
  height: 40px;
  border-radius: 4px;
  padding: 8px 12px;
`

export const StyledDrawer = styled(Drawer)`
  display: flex;
  flex-direction: column;
`

export const DrawerHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  background-color: rgba(235, 239, 243, 0.8);
  height: 60px;
  align-items: center;
  padding-left: 20px;
`

export const DrawerTitle = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: rgba(53, 56, 71, 1);
`

export const DrawerContent = styled("div")`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: white;
`

export const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`

export const DrawerDataRow = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  @media (max-width: ${theme.breakpoints.values["xs"]}px) {
    flex-direction: column;
  }
`

export const DrawerDataHeading = styled(Typography)`
  font-size: 14px;
  font-weight: 700;
  width: 150px;
  color: rgba(38, 50, 56, 1);
`

export const DrawerData = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  flex: 1;
  color: rgba(38, 50, 56, 1);
`

export const ReconcileIconButton = styled(IconButton)`
  border: 1px solid #d8dbdf;
  border-radius: 4px;
  font-size: 14px;
  color: #263238;
`

export const MarkedPendingChip = styled(Chip)`
  font-size: 12px;
  font-weight: 500;
  padding-left: 8px;
  border: 0.5px solid #c596031a;
  color: #c59603;
  background: #fff3cc;
  svg {
    height: 6px;
    width: 6px;
    fill: #c59603;
  }
`

export const MarkedYesChip = styled(MarkedPendingChip)`
  border-color: #027a481a;
  color: #027a48;
  background: #ecfdf3;
  svg {
    fill: #027a48;
  }
`

export const MarkedNoChip = styled(MarkedPendingChip)`
  border-color: #e334291a;
  color: #e33429;
  background: #e334291a;
  svg {
    fill: #e33429;
  }
`

export const RefereceNoCell = styled(Box)`
  color: #3753e9;
  textdecoration: underline;
  cursor: pointer;
`

export const ExternalLink = styled(Link)`
  color: #3753e9;
  text-decoration-color: #3753e9;
  margin-right: 12px;
  cursor: pointer;
`

export const ReconcilationMessage = styled(Box)`
  font-size: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 18px;
  border-radius: 8px;
  gap: 4px;
  background: rgba(235, 239, 243, 1);
`

export const ReconcilationMessageText = styled(Box)`
  font-size: 12px;
  font-weight: 500;
`
