import { Button } from "@mui/material"
import { styled } from "@mui/system"

export const StyledButton = styled(Button)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  background-color: white;
  padding: 10px 18px;
  border-radius: 8px;
  color: #263238;
  border: 1px solid #d0d5dd;
  text-transform: unset;
  box-shadow: 0px 1px 2px 0px #0000000d;
`

export const PrimaryButton = styled(StyledButton)`
  background-color: #ffc100;
  border: none;
`

export const ReconcilePrimaryButton = styled(PrimaryButton)`
  margin: 12px;
  width: 100%;
  gap: 8px;
`
